<template>
  <div class="news-list" v-loading="pageLoading">
    <!-- 面包屑 -->
    <div class="cont">
        <div class="cont-list">
          <a class="border-b list-cell flex-row cursor-pointer p-20" :href="item.fdm_is_allow == '0'? item.fdm_url :'javascript:void(0)'" :target="item.fdm_is_allow == '0'?'blank':''" :class="item.fdm_is_allow == '1'?'not-allowed':''" v-for="(item, index) in dataList" :key="index">
            <div class="flex-cell"><i class="iconfont iconlianjiewangzhi m-r-10"></i> {{item.fdm_name}} </div>
            <div class="tiems"><i class="iconfont iconriqi m-r-10 m-l-10"></i>{{item.fdm_upload_time}}</div>
            <div class="btns"><i class="iconfont iconxiazai m-r-10"></i>点击下载</div>
          </a>
        </div>
        <pagination
          :total="total"
          :curPage="pageParams.page"
          :perPage="pageParams.size"
          @current-change="currentChange"
        />
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      pageParams: {
        page: 1,
        size: 10
      },
      total: 0,
      dataList: [],
    };
  },
  mounted() {
    // 加载数据
    l_loadData(this)
  },
  methods: {
    // @@ 改变当前页事件
    currentChange(val) {
      this.pageParams.page = val;
      l_loadData(this);
    },
    // 下载附件
    downloadFiles(item){
      if(item.fdm_is_allow == '1') return false
      window.location.href = item.fdm_url
    }
  },
};

// 获取数据
function l_loadData(pg){
  pg.pageLoading = true
  requestGet("api/download", pg.pageParams)
    .then((res) => {
      pg.dataList = res.data.pageData || []
      pg.total = res.data.count
      pg.pageLoading = false
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false
    });
}

</script>
<style lang="scss" scoped>
  .news-list{
    width: 1200px;
    margin: 0 auto;
    .cont{
        background: #fff;
        padding: 20px;
      .cont-list{
        .list-cell{
          border-bottom: 1px solid #e8e8e8;
          font-size: 14px;
          text-decoration: none;
          color: #333;
          .tiems{
              width: 300px;
          }
          .iconfont{
              font-size: 13px;
          }
        }
        .list-cell:hover{
          background: #e6f7ff;
        }
        .not-allowed{
            cursor:not-allowed;
            color: rgb(204,204,204);
        }
      }
    }
  }
</style>
